import React, { Component } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { I18n } from "react-redux-i18n";
import classes from "./WhatsAppButton.module.scss";

class WhatsAppButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      utm: "",
      isMobile: false,
      link: "",
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.showPopup, 30000);

    this.timer = setTimeout(() => {
      if (typeof window !== "undefined") {
        const utmSource = localStorage.getItem("utmSource");
        const mobileType = localStorage.getItem("mobile");

        this.setState({
          utm: utmSource,
          link: window.location.href.split("?")[0],
          isMobile: mobileType === "true",
        });
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearTimeout(this.timer);
  }

  showPopup = () => {
    this.setState({ visible: true });
  };

  hidePopup = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible, utm, isMobile, link } = this.state;
    const whatsappLink = `https://wa.me/966549740945`;

    return (
      <div className={classes.whatsAppContainer}>
        <div
          className={`${classes.popup} ${
            visible ? classes.visible : classes.invisible
          }`}
        >
          <IoIosClose
            size={15}
            color="#121212"
            className={classes.closeIcon}
            onClick={this.hidePopup}
          />
          <p className={classes.popupText}>{I18n.t("whatsAppPopUP")}</p>
        </div>
        <a
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.whatsAppButton}
        >
          <FaWhatsapp size={56} />
        </a>
      </div>
    );
  }
}

export default WhatsAppButton;
